import React from 'react'
import Layout from "../components/layout";
import Seo from "../components/SEO/SEO"
import { graphql, Link } from 'gatsby'
import "../styles/post.scss"
import { MdKeyboardArrowDown } from "react-icons/md"
import Boton from '../components/boton';





export default function Post({ data, pageContext }) {

  const datos = data.strapiPostSecurity;
  const cms = `https://api.bullhost.es`;
  const cuerpo = datos.cuerpo.replace(/src="\//g, `src="${cms}/`);
  const urlDestacada = `${cms}${datos.imagen_destacada.url}`


  function convertirFecha(fecha) {
    return new Date(fecha).toLocaleDateString(
      'es',
      {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }
    );
  }
  return (
    <Layout>
      <Seo
        title={datos.titulo}
        imagen={urlDestacada}
        pathname={`/blog/${pageContext.slug}`}
        description={datos.extracto}
      />

      <section className="meta-post" style={{ backgroundImage: `url(${urlDestacada})` }}>
        <div className="overlay"></div>
        <div className="meta-texto">
          <div className="categorias">
            {datos.categorias.map((value, index) => {
              return <span key={index}>{value.nombre}</span>
            })}
          </div>
          <h1>{datos.titulo}</h1>
          <div className="metadatos">
            <span className="fecha">{convertirFecha(datos.published_at)}</span>
          </div>
        </div>
        <a aria-label="ir a contenido" className="flecha-bajar" href="#cuerpo-post"><MdKeyboardArrowDown /></a>
      </section>
      <section className="cuerpo" id="cuerpo-post" dangerouslySetInnerHTML={{ __html: cuerpo }} />
      <section className="posts-relacionados limitador">
        <h2 className="titulo-relacionados">Entradas relacionadas</h2>
        <div className="grid-posts">
          {datos.relacionados.map((node, index) => {
            if (index < 3) {
              return (
                <article key={index} className="card-blog-index">
                  <div className="card-blog-cuerpo-index">
                    <span className="texto-fecha">{convertirFecha(node.published_at)}</span>
                    <h3><Link to={`/blog/${node.slug}/`}>{node.titulo}</Link></h3>
                    <p className="extracto">{`${node.extracto.substring(0, 150)}...`}</p>
                  </div>
                </article>
              )
            }
            return ""
          })}
        </div>
        <div className="enlace-volver">
          <Boton color="acento" url="/blog/" texto="Volver al blog" externo={false} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query ($slug: String!){
      strapiPostSecurity(slug:{eq: $slug}){
    		titulo
        extracto
        overlay_blanco
        imagen_destacada {
          alternativeText
    			url
        }
        categorias {
    			nombre
        }
        published_at
        cuerpo
        relacionados {
          titulo
          slug
          extracto
          published_at
        }
      }
    }
  `
